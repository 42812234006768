<template>
  <div>
    <v-app-bar app color="white" v-if="user">
    <!-- <v-app-bar app color="white"> -->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="d-flex flex-row">
        <v-list-item>
          <v-icon>mdi-forum</v-icon>
        </v-list-item>
        <v-list-item>
          <v-icon>mdi-bell</v-icon>
        </v-list-item>
        <v-list-item>
          <v-menu
            bottom
            rounded
            origin="center center"
            offset-y
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <div
                class="avatar-btn d-flex flex-row justify-center align-center"
                v-on="on"
              >
                <v-avatar>
                  <img src="~@/assets/avatar-male.png" alt="John" />
                </v-avatar>
              </div>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar>
                    <img src="~@/assets/avatar-male.png" alt="John" />
                  </v-avatar>
                  <h3>{{ user.firstName }} {{ user.lastName }}</h3>
                  <p class="caption mt-1">
                    {{ user.email }}
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed rounded text>
                    Edit Account
                  </v-btn>
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed rounded text @click="logout">Logout</v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-list-item>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <!-- Navigation Drawer Header -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title d-flex justify-center">
            <v-img
              src="~@/assets/woto-logo.png"
              min-height="100"
              max-width="150"
              contain
            ></v-img>
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- Navigation Drawer Header END -->
      <v-divider class="my-2"></v-divider>

      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
          color="red"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="sidebar-text" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data: () => ({
    group: false,
    drawer: null,
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: `/manager/dashboard`,
      },
      {
        icon: "mdi-floor-plan",
        title: "Planning",
        to: `/manager/planning`,
      },
      {
        icon: "mdi-account-multiple",
        title: "Drivers",
        to: `/manager/drivers`,
      },
      {
        icon: "mdi-van-passenger",
        title: "Vans",
        to: `/manager/vans`,
      },
      {
        icon: "mdi-format-list-text",
        title: "Orders",
        to: "/manager/orders",
      },
      {
        icon: "mdi-format-list-checks",
        title: "Trips",
        to: "/manager/trips",
      },
      // {
      //   icon: "mdi-database-arrow-right-outline",
      //   title: "Entries",
      //   to: "/manager/evaluations",
      // },
      {
        icon: "mdi-tune-vertical",
        title: "Parameters",
        to: "/manager/parameters"
      }
    ]
  }),

  computed: {
    ...mapState({
      user: (state) => state.auth.loggedInUser,
    }),
  },

  mounted() {
    // console.log(this.user)
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logoutFromFirebase').then(() => {
        this.$router.replace("/login");
      });
    },
  }


}
</script>

<style scoped>
.sidebar-text {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
}
</style>
