<template>
  <div class="app-wrapper">
    <nav-bar />
    <v-main class="grey lighten-4 pa-10">
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
import NavBar from "@/components/shared/NavBar.vue"
export default {
  components: {
    NavBar
  }
}
</script>

<style></style>
